import React from "react";
import "./ProductCard.scss";

const ProductCard = ({
  Link,
  ProductLargeImage,
  ProductMobileImage,
  ProductImageAlt,
  ProductName,
}) => {
  return (
    <a href={Link} className="ProdCard">
      <figure className="ProdCard__Image">
        <img
          loading="lazy"
          src={ProductLargeImage}
          alt={ProductImageAlt}
          className="ProdCard__Web"
        />
        <img
          loading="lazy"
          src={ProductMobileImage}
          alt={ProductImageAlt}
          className="ProdCard__Mobile"
        />
      </figure>
      <div className="ProdCard__Text">
        <h3>{ProductName}</h3>
      </div>
    </a>
  );
};

export default ProductCard;
