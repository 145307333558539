import React, { useEffect, useState } from "react";
import { blogPosts } from "../../data/blogposts";
import { Link } from "gatsby";
import { PlayCircleFill } from "react-bootstrap-icons";
import { Row } from "react-bootstrap";
import "./InspirationGrid.scss";
import _ from "lodash";

const InspirationGrid = ({ filterSelected, text }) => {
  const useWindowWidth = () => {
    const isBrowser = typeof window !== "undefined";
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

    useEffect(() => {
      if (!isBrowser) return false;

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    return width;
  };

  const dataArray = [...blogPosts];

  const cards = _.filter(dataArray, (obj) => {
    return _.includes(obj.inspirationType, filterSelected);
  });

  return (
    <div className="InspirationGrid">
      <div className="InspirationGrid__Text">{text}</div>

      <section className="inner-center">
        <Row
          md={useWindowWidth() >= "990" ? 4 : 3}
          sm={useWindowWidth() >= "500" ? 2 : 1}
        >
          {cards?.map((item, i) => (
            <div className={item.video ? "video-post" : "blog-post"}>
              {item.video ? (
                <Link
                  to={"/inspirations/" + item.blogLink}
                  className="video-post--video-link"
                >
                  <div className="video-post--video-link--contain">
                    <img loading="lazy"
                      className="video-post--video-link--contain--video-image blog-image-web"
                      src={item.blogImageWeb}
                      alt={item.blogTitle}
                    />
                    <img loading="lazy"
                      className="video-post--video-link--contain--video-image blog-image-mobile"
                      src={item.blogImageMobile}
                      alt={item.blogTitle}
                    />
                    <div className="video-post--video-link--contain--video-image-gradient"></div>
                    <div className="video-post--video-link--contain--watch-video">
                      <div className="video-post--video-link--contain--watch-video--playCircle">
                        <PlayCircleFill />
                      </div>
                    </div>
                    <div className="video-post--video-link--contain--watch-video-text">
                      Watch Video
                    </div>
                    <div
                      style={
                        item.videoImageHasText
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      className="video-post--video-link--contain--text-container"
                    >
                      {item.blogTitle}
                    </div>
                  </div>
                </Link>
              ) : (
                <Link
                  to={"/inspirations/" + item.blogLink}
                  className="blog-post--blog-link"
                >
                  <div className="blog-post--blog-link--contain">
                    <img loading="lazy"
                      className="blog-post--blog-link--contain--blog-image blog-image-web"
                      src={item.blogImageWeb}
                      alt={item.blogTitle}
                    />
                    <img loading="lazy"
                      className="blog-post--blog-link--contain--blog-image blog-image-mobile"
                      src={item.blogImageMobile}
                      alt={item.blogTitle}
                    />
                  </div>
                  <div className="blog-post--blog-link--text-container">
                    <p className="blog-post--blog-link--text-container--blog-title">
                      {item.blogTitle}
                    </p>
                    <p className="blog-post--blog-link--text-container--blog-author">
                      {item.blogPostAuthor}
                    </p>
                  </div>
                </Link>
              )}
            </div>
          ))}
        </Row>
      </section>
    </div>
  );
};

export default InspirationGrid;
