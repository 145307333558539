import React from "react";
import Layout from "../components/Layout";
import ProductPage from "../components/ProductPage/ProductPage";
import { turkeyProducts } from "../data/turkey/turkeyProduct";
import { turkeyRecipes } from "../data/turkeyRecipes";
import RecipeGrid from "../components/RecipeGrid/RecipeGrid";
import InspirationGrid from "../components/InspirationGrid/InspirationGrid";
import { hamRecipes } from "../data/hamRecipes";
import _ from "lodash";
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
const Turkey = (props) => {
  const dataArray = [...hamRecipes, ...turkeyRecipes];

  const filterData = _.filter(dataArray, (obj) => {
    return _.includes(obj.filterProtein, "turkey");
  });
  const turkeyImg = getImage(props.data.allFile.edges[0].node);
  return (
    <Layout title="Turkey" description="">
      <main>
        <ProductPage
          image={turkeyImg}
          proteinProduct={turkeyProducts}
          ProductName="Turkey"
        />
        <RecipeGrid
          filteredCards={filterData}
          text={"DELICIOUS TURKEY RECIPES"}
        />

        <div>
          <InspirationGrid
            filterSelected="turkey"
            text={"TURKEY INSPIRATIONS"}
          />
        </div>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: { relativePath: { in: "turkey/SECTION-HEADER-TURKEY.jpg" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              blurredOptions: { toFormat: WEBP }
              formats: WEBP
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 100
              webpOptions: { quality: 100 }
            )
          }
        }
      }
    }
  }
`;

export default Turkey;
