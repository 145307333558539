import React from "react";
import "./ProductPage.scss";
import ProductCard from "../Productcard/ProductCard";
import { Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

const ProductPage = ({ image, proteinProduct, ProductName }) => {
  return (
    <>
      <section className="article">
        <picture className="picture">
          <source media="(min-width: 0px)" srcSet={image} />
          <GatsbyImage loading="lazy" image={image} alt={ProductName} />
        </picture>
        <div class="inner">
          <div class="half pad-top pad-bottom header">
            <h1 class="white">{ProductName}</h1>
            <p class="white">
              <big></big>
            </p>
          </div>
        </div>
      </section>

      <section class="row">
        <div class="inner centered">
          <h2 class="pad-top margin-bottom-0">
            PRODUCT AND NUTRITIONAL INFORMATION
          </h2>
        </div>
      </section>

      <Row className="inner pad-top">
        {proteinProduct.map((element, index) => {
          return (
            <Col xs={12} xl={4} sm={6} lg={4} md={4}>
              <ProductCard
                Link={`/${ProductName.toLowerCase()}/` + element.path}
                ProductName={element.recipeName}
                ProductLargeImage={element.image}
                ProductMobileImage={element.mobileImage}
                ProductImageAlt={element.recipeName}
              />
            </Col>
          );
        })}
      </Row>

      <section class="row grid pad-top">
        <div class="inner">
          <table class="nutrition-table">
            <tbody>
              <tr>
                <td></td>
                <td>
                  <span>&nbsp;&nbsp;Serving Size&nbsp;&nbsp;</span>
                </td>
                <td>
                  <span>Calories</span>
                </td>
                <td>
                  <span>Sat Fat</span>
                </td>
                <td>
                  <span>Cholesterol</span>
                </td>
                <td>
                  <span>Carbs</span>
                </td>
                <td>
                  <span>Sodium</span>
                </td>
                <td>
                  <span>Protein</span>
                </td>
              </tr>
              {proteinProduct.map((element, index) => {
                return (
                  <tr>
                    <td>{element.recipeName}</td>
                    <td>
                      {element.nutrients.servingSize[0]} <small>oz</small>
                    </td>
                    <td>{element.nutrients.calories}</td>
                    <td>
                      {element.nutrients.saturatedFat[0]} <small>G</small>
                    </td>
                    <td>
                      {element.nutrients.cholesterol[0]} <small>MG</small>
                    </td>
                    <td>
                      {element.nutrients.totalCarbs[0]} <small>G</small>
                    </td>
                    <td>
                      {element.nutrients.sodium[0]} <small>MG</small>
                    </td>
                    <td>
                      {element.nutrients.protein[0]} <small>G</small>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default ProductPage;
